import { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { icon_imgadd, icon_trashbin, icon_plus } from 'assets/img/icon';
import { ModalContext } from 'modules/context/ModalContext';
import { spaceAPI } from 'api';
import ImageDetailModal from 'components/modal/ImageDetailModal';
import { pagePaths } from 'modules/defines/paths';
import { TailSpin } from 'react-loader-spinner';
import { download } from 'assets/img/button';

const SpaceDetailImage = ({
  s_id,
  havePermission,
  imageDataLoading,
  imageData,
  imageDataRefetch,
  videoData,
  videoDataRefetch,
  openModalPanorama,
  openModalPanoramaNew,
}) => {
  const [representImage, setRepresentImage] = useState();
  const [menuImage, setMenuImage] = useState();
  const [detailImage, setDetailImage] = useState();
  const [reelsImage, setReelsImage] = useState();
  const [video, setVideo] = useState();
  const { openModal, closeModal } = useContext(ModalContext);

  const [imageRepUploadLoading, setImageRepUploadLoading] = useState(false);
  const [imageDetailUploadLoading, setImageDetailUploadLoading] =
    useState(false);
  const [imageMenuUploadLoading, setImageMenuUploadLoading] = useState(false);
  const [imageReelsUploadLoading, setImageReelsUploadLoading] = useState(false);
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);

  // 우클릭 세팅
  const [show, setShow] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [choosedPicId, setChoosedPicId] = useState(0);
  const [imageLink, setImageLink] = useState('');
  const [imageType, setImageType] = useState('');
  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    setRepresentImage(
      imageData?.data?.filter((data) => data.type === 'represent')
    );
    setMenuImage(imageData?.data?.filter((data) => data.type === 'menu'));
    setReelsImage(imageData?.data?.filter((data) => data.type === 'reels'));
    setDetailImage(imageData?.data?.filter((data) => data.type === 'default'));
    setVideo(videoData?.data?.filter((data) => data.type === 'video'));
  }, [imageData, videoData]);

  const uploadImagePromise = (formData, headers) => {
    return new Promise((resolve, reject) => {
      spaceAPI
        .uploadImage(formData, headers)
        .then((response) => resolve(response))
        .catch((e) => {
          reject(e);
        });
    });
  };

  const uploadImageListener = async (e, type) => {
    if (type === 'default') setImageDetailUploadLoading(true);
    else if (type === 'menu') setImageMenuUploadLoading(true);
    else if (type === 'reels') setImageReelsUploadLoading(true);

    var temp_list = [...e.target.files].sort((a, b) => {
      if (type === 'default') {
        if (Number(a.name.split('.')[0]) > Number(b.name.split('.')[0]))
          return 1;
        else if (Number(a.name.split('.')[0]) === Number(b.name.split('.')[0]))
          return 0;
        else return -1;
      } else {
        if (
          Number(a.name.split('.')[0].split('_')[1]) >
          Number(b.name.split('.')[0].split('_')[1])
        )
          return 1;
        else if (
          Number(a.name.split('.')[0].split('_')[1]) ===
          Number(b.name.split('.')[0].split('_')[1])
        )
          return 0;
        else return -1;
      }
    });
    for (let i = 0; i < temp_list.length; i++) {
      let file = temp_list[i];
      let blob = file.slice(0, file.size, file.type);
      let postData = new FormData();

      postData.append(
        'img',
        new File(
          [blob],
          s_id +
            '_default_' +
            (i + 1).toString() +
            '.' +
            temp_list[i].name.split('.').pop()
        )
      );
      let headers = {
        type: type,
        id: s_id,
      };

      await uploadImagePromise(postData, headers).then((response) => {
        if (response.data) {
          imageDataRefetch();
        } else {
          alert(
            '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
        setImageDetailUploadLoading(false);
        setImageMenuUploadLoading(false);
        setImageReelsUploadLoading(false);
      });
    }
  };

  const uploadRepresentImageListener = async (e, type) => {
    setImageRepUploadLoading(true);
    e.preventDefault();
    let file = e.target.files[0];
    let blob = file.slice(0, file.size, file.type);
    let postData = new FormData();
    let headers = {
      type: type,
      id: s_id,
    };
    postData.append(
      'img',
      new File(
        [blob],
        s_id + '_represent.' + e.target.files[0].name.split('.').pop()
      )
    );
    let error = false;
    try {
      await spaceAPI.uploadImage(postData, headers);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageDataRefetch();
      setImageRepUploadLoading(false);
    }
  };

  const uploadImageForwardListener = async (e, type, choosedPicId) => {
    let postData = new FormData();
    postData.append('type', type);
    postData.append('id', s_id);
    postData.append('img', e.target.files[0]);
    postData.append('selected_image_id', choosedPicId);
    let error = false;
    try {
      const response = await spaceAPI.uploadImageForward(postData);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageDataRefetch();
    }
  };

  const uploadVideoPromise = (formData, headers) => {
    return new Promise((resolve, reject) => {
      spaceAPI
        .uploadVideo(formData, headers)
        .then((response) => resolve(response))
        .catch((e) => {
          reject(e);
        });
    });
  };

  const uploadVideo = async (e) => {
    setVideoUploadLoading(true);
    let postData = new FormData();
    var temp_file = e.target.files[0];
    let blob = temp_file.slice(0, temp_file.size, temp_file.type);
    postData.append('id', s_id);
    postData.append(
      'video',
      new File([blob], s_id + '_video.' + temp_file.name.split('.').pop())
    );
    let headers = {
      id: s_id,
    };
    let error = false;
    try {
      await uploadVideoPromise(postData, headers).then((response) => {
        if (response.data) {
          // imageDataRefetch();
        } else {
          alert(
            '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
      });
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '동영상을 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      videoDataRefetch();
      setVideoUploadLoading(false);
    }
  };

  const deleteImageListener = async (e, choosedPicId) => {
    if (!window.confirm('해당 이미지를 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: choosedPicId,
    };
    let error = false;
    try {
      const response = await spaceAPI.deleteImage(postData);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageDataRefetch();
    }
  };

  const deleteAllImageListener = async (e, type) => {
    if (!window.confirm('해당 메뉴의 이미지를 모두 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: s_id,
      type: type,
    };
    let error = false;
    try {
      const response = await spaceAPI.deleteAllImage(postData);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      imageDataRefetch();
    }
  };

  const deleteAllVideoListener = async (e, type) => {
    if (!window.confirm('해당 동영상을 모두 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: s_id,
      type: type,
    };
    let error = false;
    try {
      const response = await spaceAPI.deleteAllVideo(postData);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '동영상을 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      videoDataRefetch();
    }
  };

  // Modal : 이미지 창
  const ImageDetailModalOpen = async (e, url, imgId) => {
    e.preventDefault();
    return openModal(
      <ImageDetailModal
        closeModal={closeModal}
        url={url}
        imgId={imgId}
        imageDataRefetch={imageDataRefetch}
      />
    );
  };

  const handleDownload = async (url, filename) => {
    const extension = url.split('.').pop()?.split(/\#|\?/)[0] || 'mp4'; // 예: "mp4"

    const response = await fetch(url);
    const blob = await response.blob();

    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${filename}.${extension}`; // 확장자 붙이기
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(blobUrl);
  };

  return (
    <Container>
      <HeaderContainer>
        <ContainerTitle>이미지 & XR Viewer</ContainerTitle>
      </HeaderContainer>
      {!imageDataLoading && (
        <ContentsContainer>
          {/* 대표이미지 */}
          <ContentsWrapper>
            <ContentsTitleWrapper>
              <ContentsTitle>대표 이미지</ContentsTitle>
              {imageRepUploadLoading && (
                <ContentsLoadingWrapper>
                  <TailSpin color={palette.mainBlue} width={16} height={16} />
                  이미지 업로드 중...
                </ContentsLoadingWrapper>
              )}
            </ContentsTitleWrapper>

            <ImageWrapper>
              {representImage?.map((img) => (
                <ImageContents
                  uploadedImg={img?.url}
                  key={img?.url}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setAnchorPoint({ x: e.pageX, y: e.pageY });
                    setShow(true);
                    setChoosedPicId(img?.id);
                    setImageLink(img?.url);
                    setImageType('represent');
                  }}
                  onClick={(e) => ImageDetailModalOpen(e, img?.url, img?.id)}
                />
              ))}
              <ImageUploadButton
                htmlFor="upload-represent-image"
                icon={icon_imgadd}
              />
              {havePermission && (
                <ImageDeleteButton
                  onClick={(e) => deleteAllImageListener(e, 'represent')}
                />
              )}
              {havePermission && (
                <ImageUploadInput
                  type="file"
                  id="upload-represent-image"
                  onChange={(e) => uploadRepresentImageListener(e, 'represent')}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
              )}
            </ImageWrapper>
          </ContentsWrapper>
          {/* 상세이미지 */}
          <ContentsWrapper>
            <ContentsTitleWrapper>
              <ContentsTitle>상세 이미지</ContentsTitle>
              {imageDetailUploadLoading && (
                <ContentsLoadingWrapper>
                  <TailSpin color={palette.mainBlue} width={16} height={16} />
                  이미지 업로드 중...
                </ContentsLoadingWrapper>
              )}
            </ContentsTitleWrapper>

            <ImageWrapper>
              {detailImage?.map((img) => {
                return (
                  <ImageContents
                    uploadedImg={img?.url}
                    key={img?.url}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setAnchorPoint({ x: e.pageX, y: e.pageY });
                      setShow(true);
                      setChoosedPicId(img?.id);
                      setImageLink(img?.url);
                      setImageType('default');
                    }}
                    onClick={(e) => ImageDetailModalOpen(e, img?.url, img?.id)}
                  />
                );
              })}
              <ImageUploadButton
                htmlFor="upload-detail-image"
                icon={icon_imgadd}
              />
              {havePermission && (
                <ImageDeleteButton
                  onClick={(e) => deleteAllImageListener(e, 'default')}
                />
              )}
              {havePermission && (
                <ImageUploadInput
                  type="file"
                  id="upload-detail-image"
                  multiple={true}
                  onChange={(e) => uploadImageListener(e, 'default')}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
              )}
            </ImageWrapper>
          </ContentsWrapper>
          {/* 메뉴이미지 */}
          <ContentsWrapper>
            <ContentsTitleWrapper>
              <ContentsTitle>메뉴 이미지</ContentsTitle>
              {imageMenuUploadLoading && (
                <ContentsLoadingWrapper>
                  <TailSpin color={palette.mainBlue} width={16} height={16} />
                  이미지 업로드 중...
                </ContentsLoadingWrapper>
              )}
            </ContentsTitleWrapper>
            <ImageWrapper>
              {menuImage?.map((img) => (
                <ImageContents
                  uploadedImg={img?.url}
                  key={img?.url}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setAnchorPoint({ x: e.pageX, y: e.pageY });
                    setShow(true);
                    setChoosedPicId(img?.id);
                    setImageLink(img?.url);
                    setImageType('menu');
                  }}
                  onClick={(e) => ImageDetailModalOpen(e, img?.url, img?.id)}
                />
              ))}
              <ImageUploadButton
                htmlFor="upload-menu-image"
                icon={icon_imgadd}
              />
              {havePermission && (
                <ImageDeleteButton
                  size="30"
                  onClick={(e) => deleteAllImageListener(e, 'menu')}
                />
              )}
              {havePermission && (
                <ImageUploadInput
                  type="file"
                  id="upload-menu-image"
                  multiple={true}
                  onChange={(e) => uploadImageListener(e, 'menu')}
                  onClick={(e) => {
                    // e.target.value = null;
                  }}
                />
              )}
            </ImageWrapper>
          </ContentsWrapper>
          {/* 메뉴이미지 */}
          <ContentsWrapper>
            <ContentsTitleWrapper>
              <ContentsTitle>릴스 피드</ContentsTitle>
              {imageReelsUploadLoading && (
                <ContentsLoadingWrapper>
                  <TailSpin color={palette.mainBlue} width={16} height={16} />
                  이미지 업로드 중...
                </ContentsLoadingWrapper>
              )}
            </ContentsTitleWrapper>
            <ImageWrapper>
              {reelsImage?.map((img) => (
                <ImageContents
                  uploadedImg={img?.url}
                  key={img?.url}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setAnchorPoint({ x: e.pageX, y: e.pageY });
                    setShow(true);
                    setChoosedPicId(img?.id);
                    setImageLink(img?.url);
                    setImageType('reels');
                  }}
                  onClick={(e) => ImageDetailModalOpen(e, img?.url, img?.id)}
                />
              ))}
              <ImageUploadButton
                htmlFor="upload-reels-image"
                icon={icon_imgadd}
              />
              {havePermission && (
                <ImageDeleteButton
                  size="30"
                  onClick={(e) => deleteAllImageListener(e, 'reels')}
                />
              )}
              {havePermission && (
                <ImageUploadInput
                  type="file"
                  id="upload-reels-image"
                  multiple={true}
                  onChange={(e) => uploadImageListener(e, 'reels')}
                  onClick={(e) => {
                    // e.target.value = null;
                  }}
                />
              )}
            </ImageWrapper>
          </ContentsWrapper>
          {/* 동영상 */}
          <ContentsWrapper>
            <ContentsTitleWrapper>
              <ContentsTitle>릴스 영상</ContentsTitle>
              {videoUploadLoading && (
                <ContentsLoadingWrapper>
                  <TailSpin color={palette.mainBlue} width={16} height={16} />
                  영상 업로드 중...
                </ContentsLoadingWrapper>
              )}
            </ContentsTitleWrapper>

            <ImageWrapper>
              {video?.map((img, index) => (
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <VideoContents controls controlsList="nodownload">
                    <source src={img?.url} />
                  </VideoContents>
                  <DownloadButton
                    onClick={() =>
                      handleDownload(img.url, `video_${index + 1}`)
                    }
                  >
                    <DownloadIcon />
                    <span className="text">다운로드</span>
                  </DownloadButton>
                </div>
              ))}

              <VideoUploadButton
                htmlFor="upload-space-video"
                icon={icon_imgadd}
              />
              {havePermission && (
                <VideoDeleteButton
                  size="30"
                  onClick={(e) => deleteAllVideoListener(e, 'video')}
                />
              )}
              {havePermission && (
                <VideoUploadInput
                  type="file"
                  id="upload-space-video"
                  accept="video/mp4,video/mkv, video/x-m4v, video/*"
                  onChange={(e) => uploadVideo(e)}
                  onClick={(e) => {
                    // e.target.value = null;
                  }}
                />
              )}
            </ImageWrapper>
          </ContentsWrapper>
          {/* FAVIEW */}
          <ContentsWrapper>
            <ContentsTitleWrapper>
              <ContentsTitle>FAVIEW - XR 뷰어</ContentsTitle>
            </ContentsTitleWrapper>
            <SpaceBetweenLayout>
              <ImageUploadButton
                onClick={(e) => {
                  openModalPanorama(e);
                }}
                icon={icon_plus}
              />
              <ImageUploadButton
                onClick={(e) => {
                  openModalPanoramaNew(e);
                }}
                icon={icon_plus}
              />
              <FAVIEWLinkContainer>
                <ContentsTitle>뷰어로 이동</ContentsTitle>
                <FAVIEWLinkBtn
                  onClick={() =>
                    window.open(
                      pagePaths.faview_admin.replace(':id', s_id),
                      '_blank'
                    )
                  }
                >
                  /admin
                </FAVIEWLinkBtn>
                <FAVIEWLinkBtn
                  onClick={() =>
                    window.open(
                      pagePaths.faview_vr.replace(':id', s_id),
                      '_blank'
                    )
                  }
                >
                  /vr
                </FAVIEWLinkBtn>
              </FAVIEWLinkContainer>
            </SpaceBetweenLayout>
          </ContentsWrapper>
          {/* 우클릭 메뉴 */}
          {havePermission && (
            <CustomContextMenu
              style={{
                display: show ? 'block' : 'none',
                top: anchorPoint.y,
                left: anchorPoint.x,
              }}
            >
              <CustomContextMenuItem
                onClick={(e) => deleteImageListener(e, choosedPicId)}
              >
                삭제
              </CustomContextMenuItem>
              {/* <CustomContextMenuItem>
                <label htmlFor="upload-context-image">
                  앞에 사진 추가
                  <ImageUploadInput
                    type="file"
                    id="upload-context-image"
                    onChange={(e) =>
                      uploadImageForwardListener(e, imageType, choosedPicId)
                    }
                  />
                </label>
              </CustomContextMenuItem> */}
              <CustomContextMenuItem>
                <a href={imageLink} download>
                  사진 다운로드
                </a>
              </CustomContextMenuItem>
            </CustomContextMenu>
          )}
        </ContentsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerTitle = styled.div`
  font-family: Pretendard-b;
  font-size: 18px;
  color: ${palette.fontDefault};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentsContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentsTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ContentsLoadingWrapper = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
  font-family: Pretendard-r;
  font-size: 10px;
  color: ${palette.fontBlue};
  font-weight: 400;
  gap: 8px;
`;
const ContentsTitle = styled.div`
  font-family: Pretendard-sb;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: ${palette.fontDefault};
  margin-bottom: 6px;
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const ImageContents = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `${props.initialImg}` : `${props.uploadedImg}`});
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
  cursor: pointer;
`;

const ImageUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
  cursor: pointer;
`;

const ImageUploadInput = styled.input`
  display: none;
  cursor: pointer;
`;

const ImageDeleteButton = styled.div`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: url(${icon_trashbin}) 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 8px;
  color: white;
  :hover {
    background-color: ${palette.red};
  }
  cursor: pointer;
`;

const VideoContents = styled.video`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 200px;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
`;

const VideoUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

const VideoDeleteButton = styled.div`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: url(${icon_trashbin}) 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 8px;
  color: white;
  :hover {
    background-color: ${palette.red};
  }
`;

const VideoUploadInput = styled.input`
  display: none;
`;

const FAVIEWLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FAVIEWLinkBtn = styled.div`
  font-family: Pretendard-sb;
  color: ${palette.white};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${palette.mainBlue};
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0px;
  width: 80px;
`;

const SpaceBetweenLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

// 우클릭 메뉴
const CustomContextMenu = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: fixed;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 99;
`;

const CustomContextMenuItem = styled.li`
  padding: 10px 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

const DownloadIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${download});
  background-repeat: no-repeat;
  background-position: center;
`;

const DownloadButton = styled.button`
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
  align-items: center;
  color: white;
  border: none;
  border-radius: 1000px;
  cursor: pointer;
  overflow: hidden;
  transition: width 0.3s ease;
  white-space: nowrap;
  height: 28px;

  &:hover .text {
    opacity: 1;
    width: auto;
    margin-left: 8px;
  }

  .text {
    opacity: 0;
    width: 0;
    font-size: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  svg {
    flex-shrink: 0;
  }
`;

export default SpaceDetailImage;
